<template>
  <div class="mobile-container" v-if="goodsInfo && goodsInfo.Id">
    <mobileStatusBar title="商品详情" mode="gray" :shareData="shareData"  />
    <router-link class="popup-btn" :to="{path: '/orderWithGoods', query: {id: goodsInfo.Id}}">
      <span>订单查询</span>
      <img src="../../assets/images/right-white-icon.png" />
    </router-link>
    <div class="goods-info">
      <div class="img img-cover">
        <img v-if="goodsInfo.GoodsThumb" :src="goodsInfo.GoodsThumb" />
        <img v-else src="../../assets/images/no-image-icon.png" />
      </div>
      <div class="info">
        <div class="name">
          <span v-if="goodsInfo.Lable">{{ goodsInfo.Lable }}</span>{{ goodsInfo.GoodsName }}
        </div>
        <div class="desc">{{ goodsInfo.GoodsDesc }}</div>
        <div class="flex-1"></div>
        <div class="footer">
          <div class="money">¥ <span>{{ goodsInfo.GoodsPrice }}</span></div>
          <div class="btn" :class="goodsInfo.IsCollect > 0 ? 'active' : ''" @click="handleCollection">
            <img v-if="goodsInfo.IsCollect > 0" src="../../assets/images/like-active-icon.png" />
            <img v-else src="../../assets/images/like-icon.png" />
            {{ goodsInfo.IsCollect > 0 ? '取消' : '收藏' }}
          </div>
        </div>
      </div>
    </div>
    <div class="goods-detail">
      <div class="content">
        <div class="title-item">商品介绍</div>
        <div class="detail"><div v-html="goodsInfo.GoodsDetail"></div></div>
      </div>
    </div>
    <div class="order-item">
      <router-link v-if="!userInfo || !userInfo.Id" class="need-login" :to="{path: '/login'}"></router-link>
      <van-sticky>
        <div class="order-nav">
          <div class="nav nav-1" :class="orderType == 1 ? 'active' : ''" @click="handleChangeOrderType(1)">
            普通下单
            <div class="arrow"></div>
          </div>
          <div class="nav nav-2" :class="orderType == 2 ? 'active' : ''" @click="handleChangeOrderType(2)">
             {{ goodsInfo.IsPl != 1 ? '暂停' : '' }}批量下单
            <div class="arrow"></div>
          </div>
        </div>
      </van-sticky>
      <div v-if="orderType == 1" class="order-type-item type-1">
        <div class="item" v-for="(item, index) in goodsInfo.ParamsTemplate" :key="index">
          <div class="title">{{ item.name }}</div>
          <div v-if="item.type == 2" class="textarea"><textarea v-model="item.value" :placeholder="item.description" rows="2"></textarea></div>
          <div v-else-if="item.type == 4" class="input"><input v-model="item.value" :placeholder="item.description" type="password" /></div>
          <div v-else-if="item.type == 3 || item.type == 5 || item.type == 8" class="picker" @click="handleShowPicker(item, index)">
            <input v-model="item.value" :placeholder="item.description" readonly type="text" />
            <img src="../../assets/images/arrow-down-icon.png" />
          </div>
          <div v-else-if="item.type == 6" class="input"><input v-model="item.value" :placeholder="item.description" type="number" @blur="handleVerifyInput(item, index)" /></div>
          <van-stepper v-else-if="item.type == 7" v-model="item.value" :step="item.verify.bs" :min="item.verify.bs || 1" />
          <template v-else-if="item.type == 63">
            <div v-if="item.value && item.isInput" class="input"><input v-model="item.content" readonly @click="item.isInput = false" /></div>
            <div v-else class="input"><input v-model="item.qq" placeholder="QQ号码" type="text" @blur="handleVerifyInput(item, index)" /></div>
          </template>
          <div v-else class="input"><input v-model="item.value" :placeholder="item.description" type="text" @blur="handleVerifyInput(item, index)" /></div>
        </div>
        <div class="item">
          <div class="title">执行方式</div>
          <div class="zx-type">
            <div class="type" :class="zxType == 1 ? 'active' : ''" @click="zxType = 1">立即执行</div>
            <div v-if="goodsInfo.GoodsType == 1 && zxOrder.dszx == 1" class="type" :class="zxType == 2 ? 'active' : ''" @click="zxType = 2">定时执行</div>
            <div v-if="goodsInfo.GoodsType == 1 && zxOrder.jshzx == 1" class="type" :class="zxType == 3 ? 'active' : ''" @click="zxType = 3">结束后执行</div>
            <div v-if="goodsInfo.GoodsType == 1 && zxOrder.dayzx == 1" class="type" :class="zxType == 4 ? 'active' : ''" @click="zxType = 4">每天执行</div>
          </div>
          <div v-if="zxType == 2" class="zx-detail-2" @click="handleShowDszx">
            <input v-model="dszxExpTime" type="text" readonly placeholder="选择日期时间" />
            <img src="../../assets/images/date-icon.png" />
          </div>
          <div v-if="zxType == 3" class="zx-detail-3">
            <div class="orderno">
              <input v-model="zxOrderNo" type="text" placeholder="填写之前执行的订单号" />
            </div>
            延后
            <div class="time">
              <input v-model="exeTime" type="number" />
            </div>
            分钟后执行
          </div>
          <div v-if="zxType == 4" class="zx-detail-4">
            共
            <div class="day">
              <input v-model="mtzxDay" type="number" min="1" />
            </div>
            天
            &nbsp;&nbsp;
            每天
            <div class="time" @click="handleShowMtzx">
              <input v-model="mtzxExpTime" type="text" readonly placeholder="选择时间" />
              <img src="../../assets/images/date-icon.png" />
            </div>
          </div>
        </div>
        <div class="item">
          <div class="title">数量</div>
          <div class="order-count">
            <div class="count-tip">下单数量范围({{ goodsInfo.BuyMinLimit == -1 ? '不限' : goodsInfo.BuyMinLimit }} - {{ goodsInfo.BuyMaxLimit == -1 ? '不限' : goodsInfo.BuyMaxLimit }})</div>
            <van-stepper v-model="count" :step="goodsInfo.BuyRate"  :min="goodsInfo.BuyMinLimit != -1 ? goodsInfo.BuyMinLimit : goodsInfo.BuyRate" :max="goodsInfo.BuyMaxLimit != -1 ? goodsInfo.BuyMaxLimit : Infinity" />
          </div>
        </div>
      </div>
      <div v-if="orderType == 2" class="order-type-item type-2">
        <div class="item">
          <div class="title">购买数量</div>
          <div class="zx-type">
            <div class="type" :class="countType == 1 ? 'active' : ''" @click="countType = 1">不同数量</div>
            <div class="type" :class="countType == 2 ? 'active' : ''" @click="countType = 2">随机数量</div>
            <div class="type" :class="countType == 3 ? 'active' : ''" @click="countType = 3">相同数量</div>
            <div class="empty"></div>
          </div>
        </div>
        <div v-if="countType == 2" class="count-type-item">
          <div class="title" style="margin-bottom: 0;">购买数量</div>
          <div class="input"><input v-model="countType2Min" type="number" /></div>
          <div class="line"></div>
          <div class="input"><input v-model="countType2Max" type="number" /></div>
        </div>
        <div v-if="countType == 3" class="count-type-item">
          <div class="title">购买数量</div>
          <van-stepper v-model="countType3Count" :step="goodsInfo.BuyRate" :min="goodsInfo.BuyMinLimit != -1 ? goodsInfo.BuyMinLimit : goodsInfo.BuyRate" :max="goodsInfo.BuyMaxLimit != -1 ? goodsInfo.BuyMaxLimit : Infinity" />
        </div>
        <div class="item">
          <div class="title">批量下单格式为 <img src="../../assets/images/tips-icon.png" @click="handleShowPlTip"></div>
          <div class="pl-item">
            <div class="column" v-for="(item, index) in plData" :key="index">
              <div class="num" @touchstart.prevent="handleTouchStart(index)" @touchend.prevent="handleTouchEnd(index)">{{ index + 1 }}</div>
              <div class="input"><input v-model="item.input" :readonly="item.value != ''" type="text" :placeholder="plTip" @blur="handleCheckInput(index)" /></div>
              <div class="stepper">
                <van-stepper v-if="item.value" v-model="item.count" :step="goodsInfo.BuyRate" :min="goodsInfo.BuyMinLimit != -1 ? goodsInfo.BuyMinLimit : goodsInfo.BuyRate" :max="goodsInfo.BuyMaxLimit != -1 ? goodsInfo.BuyMaxLimit : Infinity" @change="calcMoney" />
              </div>
            </div>
          </div>
          <div class="tj-item">
            <div class="count">
              已输入
              <span>{{ plData | getPlCounnt }}</span>
              行
            </div>
            <div class="btn" @click="handleClearPl">全部清除</div>
            <div class="btn" @click="handleAddPl">添加一行</div>
            <div class="btn green" @click="handleShowImport">批量导入</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-item">
      <div class="content">
        <router-link v-if="!userInfo || !userInfo.Id" class="need-login" :to="{path: '/login'}"></router-link>
        <div class="total-item">
          总计
          <span>¥</span>
          <span class="money">{{ Math.round(money * 100000) / 100000 }}</span>
        </div>
        <div v-if="orderType == 1" class="btn public-btn" :class="goodsInfo.IsClose == 2 ? 'public-btn' : 'public-not-btn'" @click="handleSubmit">
          <van-loading v-if="submiting" color="#ffffff" />
          <span v-else>{{ goodsInfo.IsClose == 2 ? '立即下单' : '暂停购买' }}</span>
        </div>
        <div v-else class="btn public-btn" :class="goodsInfo.IsClose == 2 && goodsInfo.IsPl == 1 ? 'public-btn' : 'public-not-btn'" @click="handleOneSubmit">
          <van-loading v-if="submiting" color="#ffffff" />
          <span v-else-if="goodsInfo.IsClose == 2 && goodsInfo.IsPl == 1">立即下单{{ goodsInfo.IsClose == 2 ? '' : '' }}</span>
          <span v-else-if="goodsInfo.IsClose == 1">暂停购买</span>
          <span v-else-if="goodsInfo.IsPl != 1">暂停批量下单</span>
        </div>
      </div>
    </div>
    <van-popup v-model="pickerData.show" :round="true" position="bottom">
      <van-picker
        show-toolbar
        :title="pickerData.name"
        :columns="pickerData.data"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="handleSurePicker"
        @cancel="handleCancelPicker"
      />
    </van-popup>
    <van-popup v-model="showDszx" :round="true" position="bottom">
      <van-datetime-picker 
        type="datetime"
        :filter="datetimeFilter"
        :min-date="dszxMinTime"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="handleSureDszxTime"
        @cancel="handleCancelDszxTime"
      />
    </van-popup>
    <van-popup v-model="showMtzx" :round="true" position="bottom">
      <van-datetime-picker 
        type="time"
        :filter="datetimeFilter"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="handleSureMtzxTime"
        @cancel="handleCancelMtzxTime"
      />
    </van-popup>
    <van-dialog
      v-model="showImport"
      title="批量导入"
      confirm-button-text="确定"
      cancel-button-text="取消"
      show-cancel-button
      :lazy-render="false"
      @confirm="handleSureImport"
      @cancel="handleCancelImport"
    >
      <div class="popup">
        <div class="title">
          导入格式(一行表示一个订单)
          <br />
          {{ plTip }}
        </div>
        <div class="textarea">
          <textarea v-model="importValue" rows="6" placeholder="下单参数一行一条"></textarea>
        </div>
      </div>
    </van-dialog>
    <van-popup
      v-model="qqSsPopup.show"
      :round="true"
      position="bottom"
      title="选择说说"
      @close="handleHideQqShPopup"
    >
      <div class="ss-popup">
        <div class="content">
          <van-list v-model="qqSsPopup.loading" :finished="qqSsPopup.finish" @load="handleGetQqSsDataList" loading-text="加载中..." finished-text="" error-text="加载失败">
            <div v-if="qqSsPopup.finish && !qqSsPopup.loading && qqSsPopup.dataList.length == 0" class="empty-item">
              <div class="icon img-cover"><img src="../../assets/images/empty-icon.png" /></div>
            </div>
            <div v-else class="list">
              <div v-for="(item, index) in qqSsPopup.dataList" :key="index" class="item" @click="handleSetQqData(index)">
                <div v-html="item.content"></div>
              </div>
            </div>
          </van-list>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showShare" position="bottom" style="border-radius: .24rem .24rem 0 0;">
      <div class="share-popup-item">
        <div class="close img-cover" @click="showShare = false"><img src="../../assets/images/close-icon.png" /></div>
        <div class="title-item">分享立赚</div>
        <div class="name">商品链接</div>
        <div class="value">{{ shareUrl }}</div>
        <div class="tip">分享链接给朋友，赚取佣金</div>
        <div class="btn public-btn" @click="handleCopy(shareUrl)">复制链接</div>
      </div>
    </van-popup>
    <mobileSubmitOrder ref="mobileSubmitOrderDialog" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mobileStatusBar from '@/components/mobileStatusBar'
import mobileSubmitOrder from '@/components/mobileSubmitOrder'


export default {
  name: "GoodsDetail",
  components: {
    mobileStatusBar,
    mobileSubmitOrder
  },
  data() {
    return {
      shareData: {},
      showShare: false,
      shareUrl: '',
      id: '',
      goodsInfo: {
        ParamsTemplate: []
      },
      submiting: false,
      addcarting: false,
      zxOrder: {
        dszx: 1,
        jshzx: 1,
        dayzx: 1
      },
      orderType: 1,
      zxType: 1,
      count: 0,
      money: 0,
      pickerData: {
        show: false,
        index: 0,
        key: '',
        name: '',
        data: []
      },
      showDszx: false,
      dszxExpTime: '',
      dszxMinTime: '',
      zxOrderNo: '',
      exeTime: '',
      showMtzx: false,
      mtzxDay: 1,
      mtzxExpTime: '',
      countType: 1,
      countType2Min: '',
      countType2Max: '',
      countType3Count: '',
      plData: [
        {input: '', value: '', count: ''},
        {input: '', value: '', count: ''},
        {input: '', value: '', count: ''},
        {input: '', value: '', count: ''},
        {input: '', value: '', count: ''},
      ],
      plTip: '',
      importValue: '',
      showImport: false,
      touchTimer: null,
      qqSsPopup: {
        show: false,
        qq: '',
        page: '',
        finish: false,
        loading: false
      }
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    'goodsInfo.ParamsTemplate': {
      handler: function () {
        this.calcMoney()
      },
      deep: true
    },
    count() {
      this.calcMoney()
    },
    countType3Count(value) {
      const { plData } = this
      for (let i = 0; i < plData.length; i++) {
        const item = plData[i]
        if (item.value) {
          this.plData[i].count = value
        }
      }
      this.calcMoney()
    },
    mtzxDay() {
      this.calcMoney()
    },
  },
  filters: {
    getPlCounnt(val) {
      const list = val.filter(item => item.value)
      return list.length
    }
  },
  mounted() {
    this.shareUrl = window.location.href
    this.handleGetSet()
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id
      this.handleGetGoodsDetail()
    }
    this.shareData = {
      show: false,
      callback: this.shareCallback
    }
  },
  methods: {
    shareCallback() {
      this.showShare = true
    },
    handleCopy(text) {
      this.$copyText(`${text}`).then(() => {
        this.$toast('复制成功')
      }).catch(() => {
        this.$toast('复制失败')
      })
    },
    async handleVerifyInput(item, index) {
      if (item.type == 6 && !this.$TOOL.numberRegex(item.value)) {
        this.$toast(item.name + '格式不正确')
        this.$set(this.goodsInfo.ParamsTemplate[index], 'value', '')
        return
      }
      if (item.type == 21 && !this.$TOOL.qqRegex(item.value)) {
        this.$toast(item.name + '格式不正确')
        this.$set(this.goodsInfo.ParamsTemplate[index], 'value', '')
        return
      }
      if (item.type == 22 && !this.$TOOL.phoneRegex(item.value)) {
        this.$toast(item.name + '格式不正确')
        this.$set(this.goodsInfo.ParamsTemplate[index], 'value', '')
        return
      }
      if (item.type == 23 && !this.$TOOL.emailRegex(item.value)) {
        this.$toast(item.name + '格式不正确')
        this.$set(this.goodsInfo.ParamsTemplate[index], 'value', '')
        return
      }
      if (item.type == 61 && item.value) {
        let res = await this.handleGetDouYinMasterUrl(item.value)
        if (res.error == 0 && res.info) {
          this.$set(this.goodsInfo.ParamsTemplate[index], 'value', res.info)
        } else {
          this.$toast(item.name + '格式不正确')
          this.$set(this.goodsInfo.ParamsTemplate[index], 'value', '')
        }
        return
      }
      if (item.type == 62 && item.value) {
        let res = await this.handleGetdouyinkuaishouid(item.value)
        if (res.error == 0 && res.info) {
          this.$set(this.goodsInfo.ParamsTemplate[index], 'value', res.info)
        } else {
          this.$toast(item.name + '格式不正确')
          this.$set(this.goodsInfo.ParamsTemplate[index], 'value', '')
        }
        return
      }
      if (item.type == 63 && item.qq) {
        const loading = this.$loading({
          background: 'rgba(0,0,0,.3)'
        })
        let res = await this.handleGetSS(item.qq, 1)
        loading.close()
        res = JSON.parse(res)
        if (res.code == 0) {
          this.qqSsPopup = {
            show: true,
            index: index,
            qq: item.qq,
            page: 1,
            finish: res.data.length == 0,
            loading: false,
            dataList: res.data
          }
        } else {
          this.$toast(res.msg)
        }
        return
      }
      
    },
    async handleGetQqSsDataList() {
      const { qqSsPopup } = this
      const page = qqSsPopup.page + 1
      let res = await this.handleGetSS(qqSsPopup.qq, page)
      res = JSON.parse(res)
      if (res.code == 0) {
        this.qqSsPopup.dataList = [
          ...qqSsPopup.dataList,
          ...res.data
        ]
        this.qqSsPopup.page = page
        this.qqSsPopup.finish = !res.data || res.data == 0
        this.qqSsPopup.loading = false
      } else {
        this.$toast(res.msg)
      }
    },
    handleSetQqData(index) {
      const { qqSsPopup, orderType } = this
      if (orderType == 1) {
        this.$set(this.goodsInfo.ParamsTemplate[qqSsPopup.index], 'value', qqSsPopup.dataList[index].tid)
        this.$set(this.goodsInfo.ParamsTemplate[qqSsPopup.index], 'content', qqSsPopup.dataList[index].content)
        this.$set(this.goodsInfo.ParamsTemplate[qqSsPopup.index], 'isInput', true)
      }
      this.qqSsPopup.show = false
    },
    handleHideQqShPopup() {
      const { qqSsPopup, orderType } = this
      if (orderType == 1) {
        if (this.goodsInfo.ParamsTemplate[qqSsPopup.index].value) {
          this.$set(this.goodsInfo.ParamsTemplate[qqSsPopup.index], 'isInput', true)
        }
      }
    },
    handleTouchStart(index) {
      const { plData } = this
      if (plData[index].value) {
        if (this.touchTimer) {
          clearTimeout(this.touchTimer)
        }
        this.touchTimer = setTimeout(() => {
          this.handleRemovePl(index)
        }, 1000);
      }
      // touchTimer
    },
    handleTouchEnd() {
      if (this.touchTimer) {
        clearTimeout(this.touchTimer)
        this.touchTimer = null
      }
    },
    handleShowPlTip() {
      this.$dialog({
        type: 'info',
        message: '长按序号可删除' + '\n' + '下单格式(一行表示一个订单)' + ': \n' + this.plTip,
        showConfirmButton: false,
        showCancelButton: false,
        closeOnClickOverlay: true,
        messageAlign: 'left',
        className: 'pl-tips-popup'
      })
    },
    handleShowImport() {
      this.importValue = ''
      this.showImport = true
    },
    handleSureImport() {
      const { importValue, plData } = this
      if (importValue == '') {
        return
      }
      const importList = importValue.split('\n')
      let importIndex = 0
      for (let index = 0; index < plData.length; index++) {
        const item = plData[index]
        if (!item.value) {
          if (importList[importIndex]) {
            this.plData[index].input = importList[importIndex]
            importIndex++
            this.handleCheckInput(index)
          } else {
            this.handleCancelImport()
            return
          }
        }
      }

      if (importList.length > importIndex) {
        for (let i = importIndex; i < importList.length; i++) {
          const item = importList[i];
          this.plData.push({input: item, value: '', count: ''})
          this.handleCheckInput(this.plData.length - 1)
        }
      }
      this.handleCancelImport()
    },
    handleCancelImport() {
      this.showImport = false
    },
    handleChangeOrderType(type) {
      if (this.goodsInfo.IsPl != 1 && type == 2) {
        return
      }
      this.orderType = type
      this.calcMoney()
    },
    handleCheckInput(index) {
      const obj = this.plData[index]
      if (!obj.input) {
        this.plData[index].value = ''
        return
      }
      const input = obj.input.split('----')
      const { countType, countType2Min, countType2Max, countType3Count, goodsInfo } = this
      const { ParamsTemplate } = goodsInfo
      for (let i = 0; i < ParamsTemplate.length; i++) {
        const item = ParamsTemplate[i]
        if (item.type == 6 && !this.$TOOL.numberRegex(input[i])) {
          this.$toast(item.name + '格式不正确')
          return
        } else if (item.type == 7) {
          if (!/^[1-9]\d*$/.test(input[i])) {
            this.$toast(item.name +  '必须是数字')
            return
          }
          const number = parseInt(input[i])
          const bs = parseInt(item.verify.bs || 1)
          if (number % bs != 0) {
            this.$toast(item.name +  '必须是' + bs + '的倍数')
            return
          }
        } else if (item.type == 3 || item.type == 5 || item.type == 8) {
          if (!item.type_config.includes(input[i])) {
            this.$toast(item.name +  '必须是选项中的值')
            return
          }
        } else if (item.type == 21 && !this.$TOOL.qqRegex(input[i])) {
          this.$toast(item.name + '格式不正确')
          return
        } else if (item.type == 22 && !this.$TOOL.phoneRegex(input[i])) {
          this.$toast(item.name + '格式不正确')
          return
        } else if (item.type == 23 && !this.$TOOL.emailRegex(input[i])) {
          this.$toast(item.name + '格式不正确')
          return
        } else {
          if (!input[i]) {
            this.$toast(item.description)
            return
          }
        }
      }
      let count = 0
      if (input.length > ParamsTemplate.length) {
        count = input[input.length - 1]
        input.splice(input.length - 1, 1)
      }
      this.plData[index].input = input.join('----')
      this.plData[index].value = input.join('----')
      if (count) {
        this.plData[index].count = count
      } else {
        if (countType == 1) {
          this.plData[index].count = goodsInfo.BuyMinLimit || goodsInfo.BuyRate
        }
        if (countType == 2 && countType2Min && countType2Max) {
          let min = parseInt(countType2Min)
          let max = parseInt(countType2Max)
          this.plData[index].count = parseInt(min + (Math.random() * (max - min)))
        }
        if (countType == 3) {
          this.plData[index].count = countType3Count || goodsInfo.BuyMinLimit || goodsInfo.BuyRate
        }
      }
      this.calcMoney()
    },
    handleClearPl() {
      this.$dialog.confirm({
        type: 'danger',
        title: '提示',
        message: '是否清除所有数据',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.plData = [
          {input: '', value: '', count: ''},
          {input: '', value: '', count: ''},
          {input: '', value: '', count: ''},
          {input: '', value: '', count: ''},
          {input: '', value: '', count: ''}
        ]
      }).catch(() => {
      })
    },
    handleRemovePl(index) {
      this.$dialog.confirm({
        type: 'danger',
        title: '提示',
        message: '确定要删除吗？',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.plData.splice(index, 1)
        if (this.plData.length < 5) {
          this.plData.push({input: '', value: '', count: ''})
        }
      }).catch(() => {
      })
    },
    handleAddPl() {
      this.plData.push({input: '', value: '', count: ''})
    },
    datetimeFilter(type, options) {
      if (type === 'minute') {
        return options.filter(option => option % 5 === 0)
      }
      return options
    },
    handleShowMtzx() {
      this.showMtzx = true
    },
    handleSureMtzxTime(value) {
      this.mtzxExpTime = value
      this.handleCancelMtzxTime()
    },
    handleCancelMtzxTime() {
      this.showMtzx = false
    },
    handleShowDszx() {
      this.dszxMinTime = new Date()
      this.showDszx = true
    },
    handleSureDszxTime(value) {
      this.dszxExpTime = this.$TOOL.dateFormat(value, 'yyyy-MM-dd hh:mm')
      this.handleCancelDszxTime()
    },
    handleCancelDszxTime() {
      this.showDszx = false
    },
    handleShowPicker(item, index) {
      this.pickerData = {
        show: true,
        index,
        key: item.key,
        name: item.name,
        data: item.type_config
      }
    },
    handleSurePicker(val) {
      const { pickerData } = this
      this.goodsInfo.ParamsTemplate[pickerData.index].value = val
      this.handleCancelPicker()
    },
    handleCancelPicker() {
      this.pickerData = {
        show: false,
        index: 0,
        key: '',
        name: '',
        data: []
      }
    },
    async handleGetSet() {
      var res = await this.$API.getSet.post({
        Key: 'zx_order'
      })
      if (res.info) {
        const data = JSON.parse(res.info)
        this.zxOrder = data
      }
    },
    async handleGetGoodsDetail() {
      const { userInfo } = this
      if (userInfo && userInfo.Id && userInfo.UserIsGoods != 1) {
        return
      }
      const { id, websiteInfo } = this
      var res = await this.$API.goodsDetail.post({
        Id: id,
        AdminId: websiteInfo.Id
      })
      if (res.error == 0) {
        try {
          res.info.ParamsTemplate = JSON.parse(res.info.ParamsTemplate)
        } catch (error) {
          console.log(error)
        }
        const plTipArr = []
        for (let i = 0; i < res.info.ParamsTemplate.length; i++) {
          const item = res.info.ParamsTemplate[i]
          if (item.type == 3 || item.type == 5 || item.type == 8) {
            res.info.ParamsTemplate[i].type_config = item.type_config.split(',')
          }
          plTipArr.push(item.name)
        }
        plTipArr.push('数量');
        this.plTip = plTipArr.join('----')
        res.info.BuyRate = res.info.BuyRate || 1
        this.count = res.info.BuyMinLimit > res.info.BuyRate ? res.info.BuyMinLimit : res.info.BuyRate
        this.goodsInfo = res.info
        if (res.info.IsTc == 1) {
          this.$dialog.alert({
            type: 'info',
            title: res.info.TcTitle,
            message: res.info.TcContent,
            confirmButtonText: '确定',
          })
        }
        this.calcMoney()
      }
    },
    async handleCollection() {
      const { goodsInfo } = this
      var res = await this.$API.addGoodsCollect.post({
        GoodsId: goodsInfo.Id
      })
      if (res.error == 0) {
        this.goodsInfo.IsCollect = goodsInfo.IsCollect > 0 ? 0 : 1
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
      }
      return false
    },
    calcMoney() {
      const { goodsInfo, orderType, zxType, mtzxDay } = this
      if (orderType == 1) {
        const { count } = this
        if (!count) {
          this.money = 0
          return
        }
        let number = parseInt(count)
        const { ParamsTemplate } = goodsInfo
        if (ParamsTemplate && ParamsTemplate.length > 0) {
          for (let i = 0; i < ParamsTemplate.length; i++) {
            const item = ParamsTemplate[i]
            if (item.type == 7 || item.type == 8) {
              if (item.value) {
                number = number * parseInt(item.value)
              } else {
                this.money = 0
                return
              }
            }
          }
        }
        let money = parseFloat(goodsInfo.GoodsPrice) * number
        if (zxType == 4) {
          money = money * parseInt(mtzxDay)
        }
        money = isNaN(money) ? 0 : money
        this.money = money
      }
      if (orderType == 2) {
        const { plData } = this
        let number = 0
        for (let i = 0; i < plData.length; i++) {
          const pl = plData[i]
          if (pl.value) {
            let plNumber = parseInt(pl.count)
            const value = pl.value.split('----')
            const { ParamsTemplate } = goodsInfo
            if (ParamsTemplate && ParamsTemplate.length > 0) {
              for (let j = 0; j < ParamsTemplate.length; j++) {
                const item = ParamsTemplate[j]
                if (item.type == 7 || item.type == 8) {
                  plNumber = plNumber * parseInt(value[j])
                }
              }
            }
            number = number + plNumber
          }
        }
        this.money = parseFloat(goodsInfo.GoodsPrice) * number
      }
    },
    async handleGetDouYinMasterUrl(data) {
      var res = this.$API.getDouYinMasterUrl.post({
        Url: data
      })
      return res
    },
    async handleGetdouyinkuaishouid(data) {
      var res = this.$API.getdouyinkuaishouid.post({
        Url: data
      })
      return res
    },
    async handleGetSS(qq, page) {
      var res = await this.$API.getSS.post({
        Qq: qq,
        Page: page
      })
      return res
    },
    async handleAddCart() {
      const { addcarting, goodsInfo, count } = this
      if (addcarting) {
        return
      }
      const orderParams = []
      for (let i = 0; i < goodsInfo.ParamsTemplate.length; i++) {
        const item = goodsInfo.ParamsTemplate[i]
        let value = item.value
        if (!item.value) {
          this.$toast(item.description)
          return
        }
        orderParams.push({
          name: item.name,
          alias: item.key,
          type: item.type,
          value: value
        })
      }
      const params = {
        Switch: 1,
        GoodsId: goodsInfo.Id,
        Count: count,
        Params: JSON.stringify(orderParams)
      }
      this.addcarting = true
      var res = await this.$API.addCar.post(params)
      this.addcarting = false
      if (res.error == 0) {
        this.$toast.success(res.info)
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
      }
    },
    async handleSubmit() {
      const { submiting, goodsInfo, zxType, count, dszxExpTime, zxOrderNo, exeTime, mtzxDay, mtzxExpTime } = this
      if (goodsInfo.IsClose == 1) {
        return
      }
      if (submiting) {
        return
      }
      this.submiting = true
      setTimeout(() => {
        const orderParams = []
        for (let i = 0; i < goodsInfo.ParamsTemplate.length; i++) {
          const item = goodsInfo.ParamsTemplate[i]
          let value = item.value
          if (!item.value) {
            this.$toast(item.name + '不能为空')
            this.submiting = false
            return
          }
          orderParams.push({
            name: item.name,
            alias: item.key,
            value: value
          })
        }
        const params = {
          GoodsIds: goodsInfo.Id,
          ZxType: zxType,
          OrderNum: count,
          OrderParams: JSON.stringify([orderParams])
        }
        if (zxType == 1) {
          params.ExpTime = this.$TOOL.dateFormat(new Date())
        }
        if (zxType == 2) {
          if (!dszxExpTime) {
            this.$toast('请选择执行时间')
            this.submiting = false
            return
          }
          params.ExpTime = this.$TOOL.dateFormat(dszxExpTime)
        }
        if (zxType == 3) {
          if (!zxOrderNo) {
            this.$toast('请输入之前执行的订单号')
            this.submiting = false
            return
          }
          if (exeTime === '') {
            this.$toast('请输入延时执行时间')
            this.submiting = false
            return
          }
          params.ExeTime = exeTime
          params.ZxOrderNo = zxOrderNo
        }
        if (zxType == 4) {
          if (!mtzxDay) {
            this.$toast('请输入执行天数')
            this.submiting = false
            return
          }
          if (!mtzxExpTime) {
            this.$toast('请选择执行时间')
            this.submiting = false
            return
          }
          const max = parseInt(mtzxDay)
          for (let i = 0; i < max; i++) {
            if (i == 0) {
              params.ExpTime = this.$TOOL.dateFormat(new Date(), 'yyyy-MM-dd') + ' ' + mtzxExpTime
              params.OrderParams = [orderParams]
            } else {
              params.GoodsIds += ',' + goodsInfo.Id
              params.OrderNum += ',' + count
              params.ExpTime += ',' + this.$TOOL.dateFormat((new Date().getTime()) + (i * 24 * 3600 * 1000), 'yyyy-MM-dd') + ' ' + mtzxExpTime
              params.OrderParams.push(orderParams)
            }
          }
          params.OrderParams = JSON.stringify(params.OrderParams)
        }
        this.submiting = false
        this.$refs.mobileSubmitOrderDialog.open({
          params,
          money: this.money
        })
      }, 500);
    },
    async handleOneSubmit() {
      const { submiting, goodsInfo, plData } = this
      if (goodsInfo.IsClose == 1) {
        return
      }
      const { ParamsTemplate } = goodsInfo
      if (submiting) {
        return
      }
      const plList = plData.filter(item => item.value)
      if (plList.length == 0) {
        this.$toast('请输入下单参数')
        return
      }
      const orderParams = []
      const goodsIds = []
      const orderNum = []
      const expTime = []
      this.submiting = true
      for (let plIndex = 0; plIndex < plList.length; plIndex++) {
        const pl = plList[plIndex]
        goodsIds.push(goodsInfo.Id)
        orderNum.push(pl.count)
        expTime.push(this.$TOOL.dateFormat(new Date(), 'yyyy-MM-dd hh:mm'))
        const value = pl.value.split('----')
        const plParams = []
        for (let paramsIndex = 0; paramsIndex < ParamsTemplate.length; paramsIndex++) {
          const item = ParamsTemplate[paramsIndex]
          plParams.push({
            name: item.name,
            alias: item.key,
            value: value[paramsIndex]
          })
        }
        orderParams.push(plParams)
      }
      const params = {
        OrderParams: JSON.stringify(orderParams),
        GoodsIds: goodsIds.join(','),
        OrderNum: orderNum.join(','),
        ExpTime: expTime.join(','),
        ZxType: 1
      }
      this.submiting = false
      this.$refs.mobileSubmitOrderDialog.open({
        params,
        money: this.money
      })
    }
  }
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  min-height: 100vh;
  background-color: #f7f7f7;
}
.popup-btn {
  position: fixed;
  z-index: 10;
  right: 50%;
  margin-right: var(---max-width-50);
  top: 3.9rem;
  width: 1.66rem;
  height: .48rem;
  border-radius: .24rem 0 0 .24rem;
  background-color: #1399ff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  span {
    padding: 0 .04rem;
    font-size: .24rem;
    font-weight: 500;
  }
  img {
    width: .2rem;
    height: .2rem;
  }
}
.goods-info {
  padding: .32rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  .img {
    width: 2rem;
    height: 2rem;
    border-radius: .16rem;
    overflow: hidden;
  }
  .info {
    width: 4.62rem;
    height: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: self-start;
    flex-direction: column;
    .name {
      width: 100%;
      padding-top: .04rem;
      max-height: .8rem;
      line-height: .4rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      color: #333;
      span {
        margin-top: .04rem;
        margin-right: .1rem;
        font-size: .2rem;
        color: #fff;
        height: .32rem;
        line-height: .32rem;
        padding: 0 .12rem;
        border-radius: .04rem;
        background: linear-gradient( 315deg, #FFAA8A 0%, #FFDE58 100%);
      }
    }
    .desc {
      font-size: .24rem;
      color: #b3b3b3;
      line-height: .34rem;
      max-height: .68rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
    }
    .footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .money {
        color: #ff2442;
        font-size: .24rem;
        font-weight: bold;
        span {
          font-size: .36rem;
        }
      }
      .btn {
        cursor: pointer;
        width: 1.4rem;
        height: .48rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #1399ff;
        border: .02rem solid #1399ff;
        border-radius: .24rem;
        color: #fff;
        font-size: .24rem;
        font-weight: 500;
        &.active {
          color: #1399ff;
          background-color: #fff;
        }
        img {
          width: .32rem;
          height: .32rem;
          margin-right: .12rem;
        }
      }
    }
  }
}
.goods-detail {
  padding: .2rem .32rem .32rem;
  background-color: #fff;
  .content {
    border-radius: .16rem;
    background-color: #f7f7f7;
    .title-item {
      position: relative;
      height: .88rem;
      line-height: .88rem;
      padding-left: .24rem;
      font-weight: 500;
      font-size: .28rem;
      color: #151d2b;
      &::after {
        content: '';
        position: absolute;
        top: .28rem;
        left: 0;
        width: .06rem;
        height: .32rem;
        background-color: #1399ff;
      }
    }
    .detail {
      padding: 0 .24rem .24rem;
      color: #b3b3b3;
      font-size: .28rem;
      line-height: .48rem;
      /deep/ img {
        width: 100% !important;
        height: auto !important;
      }
    }
  }
}
.order-item {
  position: relative;
  margin-top: .2rem;
  border-radius: .24rem .24rem 0 0;
  background-color: #fff;
  overflow: hidden;
  .order-nav {
    width: 100%;
    height: 1rem;
    display: flex;
    justify-content: center;
    border-bottom: .24rem solid #1399ff;
    .nav {
      cursor: pointer;
      position: relative;
      flex: 1;
      height: 1.02rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: .32rem;
      font-weight: 500;
      color: #fff;
      background-color: #1399ff;
      &.nav-1 {
        border-bottom-right-radius: .62rem;
        .arrow {
          right: 0;
          &::after {
            border-top-right-radius: .62rem;
          }
        }
      }
      &.nav-2 {
        border-bottom-left-radius: .62rem;
        .arrow {
          left: 0;
          &::after {
            border-top-left-radius: .62rem;
          }
        }
      }
      &::after {
        content: '';
        width: 1.2rem;
        height: .08rem;
        background-color: #1399ff;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      &.active {
        color: #1399ff;
        background-color: #fff;
        &.nav-1 {
          border-right-color: #fff;
        }
        &.nav-2 {
          border-left-color: #fff;
        }
        .arrow {
          background-color: #1399ff;
          &::after {
            background-color: #fff;
          }
        }
      }
      .arrow {
        width: 1.02rem;
        height: 1.02rem;
        position: absolute;
        top: 0;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: -1px;
          bottom: 0;
          left: -1px;
        }
      }
    }
  }
  .order-type-item {
    position: relative;
    z-index: 1;
    margin-top: -.26rem;
    background-color: #fff;
    padding: .24rem .32rem;
    .title {
      position: relative;
      line-height: .4rem;
      padding-left: .28rem;
      font-size: .28rem;
      color: #151d2b;
      font-weight: 500;
      margin-bottom: .2rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &::before {
        position: absolute;
        left: 0;
        content: '*';
        color: #e12b20;
      }
      img {
        cursor: pointer;
        width: .32rem;
        height: .32rem;
        margin-left: .12rem;
      }
    }
    .item {
      margin-bottom: .32rem;
    }
    &.type-1 {
      border-radius: 0 .24rem 0 0;
      .textarea, .input, .picker {
        line-height: .4rem;
        padding: .24rem;
        border-radius: .16rem;
        background-color: #f7f7f7;
      }
      .picker {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        input {
          flex: 1;
        }
        img {
          width: .24rem;
          height: .24rem;
        }
      }
      .order-count {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .count-tip {
          color: #b3b3b3;
          font-size: .24rem;
        }
      }
      .zx-detail-2 {
        margin-top: .2rem;
        padding: 0 .24rem;
        height: .72rem;
        border-radius: .16rem;
        background-color: #f7f7f7;
        display: flex;
        justify-content: space-between;
        align-items: center;
        input {
          flex: 1;
        }
        img {
          width: .4rem;
          height: .4rem;
          margin-left: .24rem;
        }
      }
      .zx-detail-3 {
        margin-top: .2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #151d2b;
        .orderno {
          flex: 1;
          height: .72rem;
          padding: 0 .2rem;
          border-radius: .16rem;
          background-color: #f7f7f7;
          margin-right: .2rem;
        }
        .time {
          width: .6rem;
          height: .72rem;
          padding: 0 .2rem;
          border-radius: .16rem;
          background-color: #f7f7f7;
          margin: 0 .2rem;
        }
      }
      .zx-detail-4 {
        margin-top: .2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #151d2b;
        .day {
          width: 1rem;
          height: .72rem;
          padding: 0 .2rem;
          border-radius: .16rem;
          background-color: #f7f7f7;
          margin: 0 .2rem;
        }
        .time {
          flex: 1;
          height: .72rem;
          padding: 0 .2rem;
          border-radius: .16rem;
          background-color: #f7f7f7;
          margin: 0 .2rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          input {
            flex: 1;
          }
          img {
            width: .4rem;
            height: .4rem;
            margin-left: .24rem;
          }
        }
      }
    }
    .zx-type {
      width: 100%;
      height: .72rem;
      display: flex;
      align-items: center;
      .type {
        cursor: pointer;
        margin-right: .2rem;
        width: 1.56rem;
        height: .72rem;
        border-radius: .12rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .26rem;
        background-color: #f7f7f7;
        color: #b3b3b3;
        &:nth-last-child(1) {
          margin-right: 0;
        }
        &.active {
          background-color: #1399ff;
          color: #fff;
          font-weight: 500;
        }
      }
    }
    &.type-2 {
      border-radius: .24rem 0 0 0;
      .count-type-item {
        padding: 0 .32rem .32rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-size: .24rem;
          color: #151d2b;
        }
        .input {
          width: 1.8rem;
          height: .72rem;
          border-radius: .12rem;
          background-color: #f7f7f7;
          padding: 0 .24rem;
        }
        .line {
          width: .32rem;
          height: .02rem;
          background-color: #151d2b;
        }
      }
      .pl-item {
        width: 100%;
        border: .02rem solid #f7f7f7;
        border-radius: .12rem;
        .column {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: .72rem;
          border-bottom: .02rem solid #f7f7f7;
          &:nth-last-child(1) {
            border: 0;
          }
          .num {
            width: .8rem;
            height: .72rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f7f7f7;
            color: #b3b3b3;
          }
          .input {
            flex: 1;
            padding: 0 .22rem;
            input {
              font-size: .24rem;
            }
          }
          .stepper {
            width: 2.04rem;
          }
          /deep/.van-stepper {
            button {
              width: .6rem;
              height: .6rem;
            }
            input {
              width: .84rem;
              height: .6rem;
              margin: 0;
              border-left: 0;
              border-right: 0;
            }
          }
        }
      }
      .tj-item {
        margin-top: .24rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .count {
          flex: 1;
          color: #333;
          span {
            color: #1399ff;
          }
        }
        .btn {
          cursor: pointer;
          width: 1.36rem;
          height: .4rem;
          border-radius: .08rem;
          background-color: #f7f7f7;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #0b212c;
          font-size: .24rem;
          margin-left: .2rem;
          &.green {
            color: #fff;
            background-color: #1399ff;
          }
        }
      }
    }
  }
}
.popup {
  margin: .32rem;
  .title {
    color: #999;
    font-size: .24rem;
    line-height: .34rem;
    margin-bottom: .24rem;
  }
  .textarea {
    background-color: #f7f7f7;
    border-radius: .08rem;
    padding: .24rem;
  }
}
.ss-popup {
  padding: .24rem 0;
  .content {
    height: 70vh;
    overflow-x: hidden;
    overflow-y: auto;
    .item {
      border-radius: .2rem;
      padding: .2rem;
      margin: 0 .24rem .2rem;
      box-shadow: 0 0 8px 2px rgba(0, 28, 84, .1);
      font-size: .24rem;
      color: #666;
      line-height: .34rem;
    }
  }
}
.need-login {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}
.footer-item {
  width: 100%;
  height: 1.2rem;
  margin-top: .68rem;
  .content {
    position: fixed;
    bottom: 0;
    width: var(--max-width);
    left: 50%;
    margin-left: var(---max-width-50);
    z-index: 10;
    box-sizing: border-box;
    height: 1.2rem;
    padding: 0 .4rem 0 .32rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    .total-item {
      color: #151d2b;
      font-size: .24rem;
      font-weight: 500;
      span {
        font-weight: bold;
        color: #ff2442;
        font-size: .32rem;
        margin-left: .12rem;
        &.money {
          font-size: .44rem;
          margin-left: .06rem;
        }
      }
    }
    .btn {
      cursor: pointer;
      width: 2.4rem;
      height: .8rem;
      margin-bottom: .08rem;
      border-radius: .24rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: .28rem;
      font-weight: 500;
    }
  }
}
.share-popup-item {
  padding: 0 .32rem;
  height: 6.98rem;
  position: relative;
  color: #030304;
  font-weight: 500;
  .close {
    position: absolute;
    top: .32rem;
    right: .28rem;
    width: .24rem;
    height: .24rem;
    padding: .12rem;
  }
  .title-item {
    padding-top: .32rem;
    text-align: center;
    line-height: .48rem;
    font-size: .32rem;
  }
  .name {
    margin-top: .72rem;
    line-height: .4rem;
    font-size: .28rem;
  }
  .value {
    margin-top: .2rem;
    height: .88rem;
    padding: 0 .24rem;
    line-height: .88rem;
    border-radius: .16rem;
    background-color: #f7f7f7;
    color: #151d2b;
    font-size: .24rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .tip {
    margin-top: .16rem;
    font-weight: 400;
    font-size: .24rem;
    color: #ff2442;
    line-height: .34rem;
  }
  .btn {
    margin-top: 1.8rem;
    width: 100%;
    height: .88rem;
    border-radius: .12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .28rem;
    font-weight: bold;
    color: #fff;
  }
}
/deep/.van-popup--bottom {
  width: var(--max-width) !important;
  left: 50%;
  margin-left: var(---max-width-50);
}
</style>
